import type { Dispatch } from 'redux'
import type { AnalyticsCuePointsOnDemand } from '../../../config/enums'
import type { AnalyticsCuePointsOnDemandUpdateAction } from '../interfaces/AnalyticsCuePointsOnDemandState'
import type { TimestampProps } from '../interfaces/TimestampProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const analyticsCuePointsOnDemandStateUpdate = (props: Record<AnalyticsCuePointsOnDemand, TimestampProps>): AnalyticsCuePointsOnDemandUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ANALYTICS_CUE_POINTS_ON_DEMAND_STATE_UPDATE,
		props,
	}
}

export default function analyticsCuePointsOnDemandState(props: Record<AnalyticsCuePointsOnDemand, TimestampProps>) {
	return (dispatch: Dispatch): void => {
		dispatch(analyticsCuePointsOnDemandStateUpdate(props))
	}
}
