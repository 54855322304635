export enum Game {
	Blockbuster = 'Blockbuster',
	Centipede = 'Centipede',
	Beat = 'Beat',
	Rally = 'Rally',
	Breakout = 'Breakout',
}

export enum Currency {
	USD = 'USD',
	NOK = 'NOK',
}

export enum GameStage {
	FutureCity = 'futureCity',
	TropicalIsland = 'tropicalIsland',
	PinballWorld = 'pinballWorld',
	DriveInDiner = 'driveInDiner',
	Candyland = 'candyland',
	Room = 'room', // Rally specific
}

export enum TimestampAutomationCommand {
	StartShow = 'StartShow',
	StartGame = 'StartGame',
	PrepareCelebration = 'PrepareCelebration',
	DisplayBeatTheGuest = 'DisplayBeatTheGuest',
	PickWinners = 'PickWinners',
	ShowLuckyWinners = 'ShowLuckyWinners',
	StartPartBGame = 'StartPartBGame',
	StopShow = 'StopShow',
}

export enum OnDemandTimestampCommand {
	ShowLuckyWinners = 'ShowLuckyWinners',
	StartOnDemandGame = 'StartOnDemandGame',
}

export enum ShowType {
	DailyArcadeTournament = 'daily-arcade-tournament',
	SeasonFinale = 'season-finale',
	SeasonPremiere = 'season-premiere',
	MegaMonthlyGiveAway = 'mega-monthly-give-away',
}

export enum AnalyticsCuePoints {
	// Part A
	OpeningVT = 'opening-VT',
	GuestPresentationPartA = 'guest-presentation-part-a',
	StartMinigameWithGuest = 'start-minigame-with-guest',
	AnnounceNextFeaturedGame = 'announce-next-featured-game',

	// Part B
	ShowLevelForNextFeaturedGame = 'show-level-for-next-featured-game',
	GuestIntroductionPartB = 'guest-introduction-part-b',
	StartGameplayGuest = 'start-gameplay-guest',
}

export enum AnalyticsCuePointsOnDemand {
	ShowLevelForNextFeaturedGame = 'show-level-for-next-featured-game',
	GuestIntroductionPartB = 'guest-introduction-part-b',
	StartGameplayGuest = 'start-gameplay-guest',
}
