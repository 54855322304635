import { AnalyticsCuePoints } from '../../../config/enums'
import type { AnalyticsCuePointsState, AnalyticsCuePointsUpdateAction } from '../interfaces/AnalyticsCuePointsState'
import type { TimestampProps } from '../interfaces/TimestampProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const initialSettingsState: TimestampProps = {
	minute: 0,
	second: 0,
}

export const initialState: AnalyticsCuePointsState = {
	props: Object.fromEntries(Object.values(AnalyticsCuePoints).map((commandName) => [commandName, initialSettingsState])) as Record<
		AnalyticsCuePoints,
		TimestampProps
	>,
}

export default function analyticsCuePointsState(state = initialState, action: AnalyticsCuePointsUpdateAction): AnalyticsCuePointsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.ANALYTICS_CUE_POINTS_STATE_UPDATE:
			return { props: action.props }
		default:
			return state
	}
}
