export enum REDUX_DASHBOARD_INPUT_TYPES {
	VIDEO_STATE_UPDATE = 'VIDEO_STATE_UPDATE',
	VIDEO_STATE_VALID = 'VIDEO_STATE_VALID',

	ON_DEMAND_VIDEO_STATE_UPDATE = 'ON_DEMAND_VIDEO_STATE_UPDATE',
	ON_DEMAND_VIDEO_STATE_VALID = 'ON_DEMAND_VIDEO_STATE_VALID',

	BEAT_GUEST_MESSAGES_STATE_UPDATE = 'BEAT_GUEST_MESSAGES_STATE_UPDATE',
	BEAT_GUEST_MESSAGES_STATE_VALID = 'BEAT_GUEST_MESSAGES_STATE_VALID',

	PRIZES_STATE_UPDATE = 'PRIZES_STATE_UPDATE',
	PRIZES_STATE_VALID = 'PRIZES_STATE_VALID',

	NEXT_SHOW_DATE_TIME_UPDATE = 'NEXT_SHOW_DATE_TIME_UPDATE',
	NEXT_SHOW_DATE_TIME_VALID = 'NEXT_SHOW_DATE_TIME_VALID',

	BLOCKBUSTER_UPDATE = 'BLOCKBUSTER_UPDATE',
	BLOCKBUSTER_VALID = 'BLOCKBUSTER_VALID',

	CENTIPEDE_UPDATE = 'CENTIPEDE_UPDATE',
	CENTIPEDE_VALID = 'CENTIPEDE_VALID',

	BEAT_UPDATE = 'BEAT_UPDATE',
	BEAT_VALID = 'BEAT_VALID',

	RALLY_UPDATE = 'RALLY_UPDATE',
	RALLY_VALID = 'RALLY_VALID',

	BREAKOUT_UPDATE = 'BREAKOUT_UPDATE',
	BREAKOUT_VALID = 'BREAKOUT_VALID',

	FEATURED_GAME_UPDATE = 'FEATURED_GAME_UPDATE',

	NEXT_DAILY_GOALS_UPDATE = 'NEXT_DAILY_GOALS_UPDATE',
	NEXT_DAILY_GOALS_VALID = 'NEXT_DAILY_GOALS_VALID',

	NEXT_WEEKLY_GOALS_UPDATE = 'NEXT_WEEKLY_GOALS_UPDATE',
	NEXT_WEEKLY_GOALS_RESET = 'NEXT_WEEKLY_GOALS_RESET',
	NEXT_WEEKLY_GOALS_VALID = 'NEXT_WEEKLY_GOALS_VALID',

	NEXT_MONTHLY_GOALS_UPDATE = 'NEXT_MONTHLY_GOALS_UPDATE',
	NEXT_MONTHLY_GOALS_RESET = 'NEXT_MONTHLY_GOALS_RESET',
	NEXT_MONTHLY_GOALS_VALID = 'NEXT_MONTHLY_GOALS_VALID',

	POSTER_INFO_STATE_UPDATE = 'POSTER_INFO_STATE_UPDATE',
	POSTER_INFO_STATE_VALID = 'POSTER_INFO_STATE_VALID',

	NEXT_POSTER_INFO_STATE_UPDATE = 'NEXT_POSTER_INFO_STATE_UPDATE',
	NEXT_POSTER_INFO_STATE_VALID = 'NEXT_POSTER_INFO_STATE_VALID',

	FORCE_UPDATE_VERSION_STATE_UPDATE = 'FORCE_UPDATE_VERSION_STATE_UPDATE',
	FORCE_UPDATE_VERSION_STATE_VALID = 'FORCE_UPDATE_VERSION_STATE_VALID',

	TIMESTAMPS_AUTOMATION_SETTINGS_STATE_UPDATE = 'TIMESTAMPS_AUTOMATION_SETTINGS_STATE_UPDATE',
	TIMESTAMPS_AUTOMATION_SETTINGS_STATE_VALID = 'TIMESTAMPS_AUTOMATION_SETTINGS_STATE_VALID',

	ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_UPDATE = 'ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_UPDATE',
	ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_VALID = 'ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_VALID',

	ANALYTICS_CUE_POINTS_STATE_UPDATE = 'ANALYTICS_CUE_POINTS_STATE_UPDATE',

	ANALYTICS_CUE_POINTS_ON_DEMAND_STATE_UPDATE = 'ANALYTICS_CUE_POINTS_ON_DEMAND_STATE_UPDATE',

	SEASON_WINNER_STATE_UPDATE = 'SEASON_WINNER_STATE_UPDATE',
}
