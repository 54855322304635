import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { maxMinuteSecondNumber, minTimeNumber, useAnalyticsCuePoints } from '../../utils/hooks/dashboardInputs/useAnalyticsCuePoints'
import type { TimestampProps } from '../../redux/dashboardInputs/interfaces/TimestampProps'

interface Props<T extends string> {
	title: string
	cuePointsGroups: {
		title?: string
		list: string[]
	}[]
	cuePoints: Record<T, TimestampProps>
	cuePointTitles: {
		[key: string]: string
	}
	updateTimestampsSettings: (cuePoint: T) => (event: ChangeEvent<HTMLInputElement>) => void
}

function AnalyticsCuePointsElement<T extends string>({
	title,
	cuePointsGroups,
	cuePointTitles,
	cuePoints,
	updateTimestampsSettings,
}: Props<T>): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>{title}</CardHeading>
			</CardHeader>
			<CardBody>
				{cuePointsGroups.map((group, index) => (
					<div key={index}>
						{group.title && <CardHeading>{group.title}</CardHeading>}
						<CardFieldSet>
							<table style={{ margin: '0 auto' }}>
								<thead>
									<tr>
										<th>Cue point</th>
										<th>Minute</th>
										<th>Second</th>
									</tr>
								</thead>
								<tbody>
									{group.list.map((cuePoint, index) => {
										const title = cuePointTitles[cuePoint]
										const value = cuePoints[cuePoint as T]
										const onChangeFunction = updateTimestampsSettings(cuePoint as T)

										return (
											<tr key={`${cuePoint}-${index}`}>
												<td>{title}</td>
												<td>
													<input
														type="number"
														value={value.minute}
														name="minute"
														min={minTimeNumber}
														max={maxMinuteSecondNumber}
														onChange={onChangeFunction}
													/>
												</td>
												<td>
													<input
														type="number"
														value={value.second}
														name="second"
														min={minTimeNumber}
														max={maxMinuteSecondNumber}
														onChange={onChangeFunction}
													/>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</CardFieldSet>
					</div>
				))}
			</CardBody>
		</CardWrapper>
	)
}

export default AnalyticsCuePointsElement
