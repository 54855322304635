import { AnalyticsCuePointsOnDemand } from '../../../config/enums'
import type { AnalyticsCuePointsOnDemandState, AnalyticsCuePointsOnDemandUpdateAction } from '../interfaces/AnalyticsCuePointsOnDemandState'
import type { TimestampProps } from '../interfaces/TimestampProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { initialSettingsState } from './analyticsCuePointsState'

export const initialState: AnalyticsCuePointsOnDemandState = {
	props: Object.fromEntries(Object.values(AnalyticsCuePointsOnDemand).map((commandName) => [commandName, initialSettingsState])) as Record<
		AnalyticsCuePointsOnDemand,
		TimestampProps
	>,
}

export default function analyticsCuePointsOnDemandState(
	state = initialState,
	action: AnalyticsCuePointsOnDemandUpdateAction,
): AnalyticsCuePointsOnDemandState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.ANALYTICS_CUE_POINTS_ON_DEMAND_STATE_UPDATE:
			return { props: action.props }
		default:
			return state
	}
}
