import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import type { AnalyticsCuePointsOnDemand } from '../../../config/enums'
import analyticsCuePointsOnDemandState from '../../../redux/dashboardInputs/actions/analyticsCuePointsOnDemandState'
import type { TimestampProps } from '../../../redux/dashboardInputs/interfaces/TimestampProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import { minTimeNumber, maxMinuteSecondNumber, onTimestampSettingChange } from './useAnalyticsCuePoints'

type UseAnalyticsCuePointsOnDemand = [
	Record<AnalyticsCuePointsOnDemand, TimestampProps>,
	(timestampCommand: AnalyticsCuePointsOnDemand) => (event: ChangeEvent<HTMLInputElement>) => void,
	(props: Record<AnalyticsCuePointsOnDemand, TimestampProps>) => void,
]

export const useAnalyticsCuePointsOnDemand = (): UseAnalyticsCuePointsOnDemand => {
	const analyticsCuePointsOnDemand = useAppSelector(
		({
			dashboardInputs: {
				analyticsCuePointsOnDemandState: { props: analyticsCuePointsOnDemand },
			},
		}: StateTree) => analyticsCuePointsOnDemand,
	)

	const dispatch = useAppDispatch()
	const updateAnalyticsCuePointsOnDemandState = useCallback(
		(props: Record<AnalyticsCuePointsOnDemand, TimestampProps>) => analyticsCuePointsOnDemandState(props)(dispatch),
		[dispatch],
	)

	return [
		analyticsCuePointsOnDemand,
		onTimestampSettingChange(analyticsCuePointsOnDemand, updateAnalyticsCuePointsOnDemandState),
		updateAnalyticsCuePointsOnDemandState,
	]
}
