import Ajv from 'ajv'
import schema from '../../../assets/json/DashboardInputValues-schema.json'
import { AnalyticsCuePoints, AnalyticsCuePointsOnDemand, Currency, OnDemandTimestampCommand, TimestampAutomationCommand } from '../../../config/enums'
import type { Goals } from '../../../interfaces/Goals'
import type { BaseGameSettingsProps, BlockbusterSettingsProps, FeaturedGame } from './GamesSettings'
import type { DateTimeProps } from './NextShowTimeActions'
import type { TimestampProps } from './TimestampProps'

const ajv = new Ajv()

export interface DashboardInputValues {
	video: {
		highQualityVideoUrl: string
		videoUrl: string
		lowQualityVideoUrl: string
		guestScore: number
	}
	onDemand: {
		videoURLs: {
			highQuality: string
			standardQuality: string
			lowQuality: string
		}
		guestScore: number
		timestamps: Record<OnDemandTimestampCommand, TimestampProps>
	}
	beatGuestMessages: {
		yesFirst: string
		yesSecond: string
		yesOnDemand: string
		noFirst: string
		noSecond: string
	}
	prizes: {
		currency: Currency
		beatGuestWinner: number
		luckyWinner: number
		coLuckyWinner: number
		leagueWinner: { [league: string]: number }
	}
	blockbusterSettings: BlockbusterSettingsProps
	centipedeSettings: BaseGameSettingsProps
	beatSettings: BaseGameSettingsProps
	rallySettings: BaseGameSettingsProps
	breakoutSettings: BaseGameSettingsProps
	featuredGame: FeaturedGame
	nextShowDateTime: DateTimeProps
	nextDailyGoals: Goals
	nextWeeklyGoals: Goals
	nextMonthlyGoals: Goals
	Guest: {
		guest: {
			name: string
			bio: string
			socialHandle: string
			socialHyperlink: string
			imageWithName: string
			imageWithoutName: string
		}
		host: {
			name: string
			socialHandle: string
			socialHyperlink: string
			imageWithoutName: string
		}
		gameName: string
	}
	NextGuest: {
		guest: {
			name: string
			bio: string
			socialHandle: string
			socialHyperlink: string
			imageWithName: string
			imageWithoutName: string
		}
		host: {
			name: string
			socialHandle: string
			socialHyperlink: string
			imageWithoutName: string
		}
		gameName: string
	}
	forceUpdateVersion: { android: string; iOS: string }
	timestampsAutomationSettings: Record<TimestampAutomationCommand, TimestampProps>
	analyticsCuePoints: Record<AnalyticsCuePoints, TimestampProps>
	analyticsCuePointsOnDemand: Record<AnalyticsCuePointsOnDemand, TimestampProps>
}

export const validateDashboardInputValues = ajv.compile<DashboardInputValues>(schema)
