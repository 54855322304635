import { useCallback } from 'react'
import { Game } from '../../../config/enums'
import { validateDashboardInputValues } from '../../../redux/dashboardInputs/interfaces/DashboardInputValues'
import overwriteDefaultValues, { overwriteDefaultValuesFailure } from '../../../redux/studioCommand/actions/overwriteDefaultValues'
import type { GetDefaultValues } from '../../../redux/studioCommand/interfaces/GetDefaultValues'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { useAppDispatch, useAppStore } from '..'
import { useAnalyticsCuePoints } from '../dashboardInputs/useAnalyticsCuePoints'
import { useAnalyticsCuePointsOnDemand } from '../dashboardInputs/useAnalyticsCuePointsOnDemand'
import { useBaseGameSettings } from '../dashboardInputs/useBaseGameSettings'
import { useBeatGuestMessagesState } from '../dashboardInputs/useBeatGuestMessages'
import { useBlockbusterSettings } from '../dashboardInputs/useBlockbusterSettings'
import { useFeaturedGame } from '../dashboardInputs/useFeaturedGame'
import { useForceUpdateVersionState } from '../dashboardInputs/useForceUpdateVersionState'
import { usePosterInfoUpdate } from '../dashboardInputs/usePosterInfoUpdate'
import { useNextPosterInfoUpdate } from '../dashboardInputs/useNextPosterInfoUpdate'
import { useNextDailyGoalsState } from '../dashboardInputs/useNextDailyGoals'
import { useNextMonthlyGoalsState } from '../dashboardInputs/useNextMonthlyGoals'
import { useNextShowDateTime } from '../dashboardInputs/useNextShowTime'
import { useNextWeeklyGoalsState } from '../dashboardInputs/useNextWeeklyGoals'
import { usePrizesState } from '../dashboardInputs/usePrizes'
import { useTimestampAutomationSettings } from '../dashboardInputs/useTimestampAutomationSettings'
import { useOnDemandTimestampSettings } from '../dashboardInputs/useOnDemandTimestampsSettings'
import { useVideoState } from '../dashboardInputs/useVideoState'
import { useOnDemandVideoState } from '../dashboardInputs/useOnDemandVideoState'

export const useDefaultValues = (): [() => Promise<void>, () => Promise<void>] => {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const [, , , , , updateBeatGuestMessagesState] = useBeatGuestMessagesState()
	const [, updateForceUpdateVersionState] = useForceUpdateVersionState()
	const [, updatePosterInfoState] = usePosterInfoUpdate()
	const [, updateNextPosterInfoState] = useNextPosterInfoUpdate()
	const [, updateBlockbusterSettings] = useBlockbusterSettings()
	const [, updateCentipedeSettings] = useBaseGameSettings(Game.Centipede)
	const [, updateBeatSettings] = useBaseGameSettings(Game.Beat)
	const [, updateRallySettings] = useBaseGameSettings(Game.Rally)
	const [, updateBreakoutSettings] = useBaseGameSettings(Game.Breakout)
	const [, , updateFeaturedGame] = useFeaturedGame()
	const [, updateNextDailyGoalsState] = useNextDailyGoalsState()
	const [, updateNextWeeklyGoalsState] = useNextWeeklyGoalsState()
	const [, updateNextMonthlyGoalsState] = useNextMonthlyGoalsState()
	const [, updateNextShowDateTimeState] = useNextShowDateTime()
	const [, , , , , updatePrizesState] = usePrizesState()
	const [, , , , updateVideoState] = useVideoState()
	const [, , , , updateOnDemandVideoState] = useOnDemandVideoState()
	const [, , updateTimestampAutomationSettingsState] = useTimestampAutomationSettings()
	const [, , updateOnDemandTimestampSettingsState] = useOnDemandTimestampSettings()
	const [, , updateAnalyticsCuePointsState] = useAnalyticsCuePoints()
	const [, , updateAnalyticsCuePointsOnDemandState] = useAnalyticsCuePointsOnDemand()

	const sendOverwriteDefaultValues = useCallback(() => overwriteDefaultValues()(dispatch, getState), [dispatch, getState])

	const confirmOverwriteDefaultValues = async (): Promise<void> => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'Are you sure you want to overwrite the default values?'

		if (confirmDialog(confirmMessage)) {
			await sendOverwriteDefaultValues()
		} else {
			console.warn('action not confirmed')
			dispatch(overwriteDefaultValuesFailure())
		}
	}

	const replaceValuesWithDefaults = useCallback(async () => {
		const getDefaultValuesCommand: GetDefaultValues = {
			command: 'getDefaultValues',
		}
		const response = await sendCommandToStudio<unknown>(getDefaultValuesCommand)
		if (!validateDashboardInputValues(response)) {
			console.error(validateDashboardInputValues.errors)
			alert('Default values were not applied: invalid default values structure received from the server, check the logs for more information')
			return
		}
		const {
			video,
			onDemand,
			forceUpdateVersion,
			beatGuestMessages,
			Guest,
			NextGuest,
			nextShowDateTime,
			prizes,
			blockbusterSettings,
			centipedeSettings,
			beatSettings,
			rallySettings,
			breakoutSettings,
			featuredGame,
			nextDailyGoals,
			nextWeeklyGoals,
			nextMonthlyGoals,
			timestampsAutomationSettings,
			analyticsCuePoints,
			analyticsCuePointsOnDemand,
		} = response

		updateVideoState(video)
		updateOnDemandVideoState({
			highQualityVideoUrl: onDemand.videoURLs.highQuality,
			videoUrl: onDemand.videoURLs.standardQuality,
			lowQualityVideoUrl: onDemand.videoURLs.lowQuality,
			guestScore: onDemand.guestScore,
		})
		updateForceUpdateVersionState(forceUpdateVersion)
		updateBeatGuestMessagesState(beatGuestMessages)
		updatePosterInfoState({
			guestName: Guest.guest.name,
			guestBio: Guest.guest.bio,
			guestSocialHandle: Guest.guest.socialHandle,
			guestSocialHyperlink: Guest.guest.socialHyperlink,
			guestImageWithName: Guest.guest.imageWithName,
			guestImageWithoutName: Guest.guest.imageWithoutName,
			hostName: Guest.host.name,
			hostSocialHandle: Guest.host.socialHandle,
			hostSocialHyperlink: Guest.host.socialHyperlink,
			hostImageWithoutName: Guest.host.imageWithoutName,
			game: Guest.gameName,
		})
		updateNextPosterInfoState({
			guestName: NextGuest.guest.name,
			guestBio: NextGuest.guest.bio,
			guestSocialHandle: NextGuest.guest.socialHandle,
			guestSocialHyperlink: NextGuest.guest.socialHyperlink,
			guestImageWithName: NextGuest.guest.imageWithName,
			guestImageWithoutName: NextGuest.guest.imageWithoutName,
			hostName: NextGuest.host.name,
			hostSocialHandle: NextGuest.host.socialHandle,
			hostSocialHyperlink: NextGuest.host.socialHyperlink,
			hostImageWithoutName: NextGuest.host.imageWithoutName,
			game: NextGuest.gameName,
		})
		updateBlockbusterSettings(blockbusterSettings)
		updateCentipedeSettings(centipedeSettings)
		updateBeatSettings(beatSettings)
		updateRallySettings(rallySettings)
		updateBreakoutSettings(breakoutSettings)
		updateFeaturedGame(featuredGame)
		updateNextDailyGoalsState(nextDailyGoals)
		updateNextWeeklyGoalsState(nextWeeklyGoals)
		updateNextMonthlyGoalsState(nextMonthlyGoals)
		updateNextShowDateTimeState(nextShowDateTime)
		updatePrizesState(prizes)
		updateTimestampAutomationSettingsState(timestampsAutomationSettings)
		updateOnDemandTimestampSettingsState(onDemand.timestamps)
		updateAnalyticsCuePointsState(analyticsCuePoints)
		updateAnalyticsCuePointsOnDemandState(analyticsCuePointsOnDemand)
	}, [
		updateBeatGuestMessagesState,
		updateForceUpdateVersionState,
		updateBlockbusterSettings,
		updateCentipedeSettings,
		updateBeatSettings,
		updateRallySettings,
		updateBreakoutSettings,
		updateFeaturedGame,
		updateNextDailyGoalsState,
		updateNextWeeklyGoalsState,
		updateNextMonthlyGoalsState,
		updateNextShowDateTimeState,
		updatePrizesState,
		updateVideoState,
		updateTimestampAutomationSettingsState,
		updateAnalyticsCuePointsState,
		updateAnalyticsCuePointsOnDemandState,
	])

	return [confirmOverwriteDefaultValues, replaceValuesWithDefaults]
}
