import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import type { AnalyticsCuePoints } from '../../../config/enums'
import analyticsCuePointsState from '../../../redux/dashboardInputs/actions/analyticsCuePointsState'
import type { TimestampProps } from '../../../redux/dashboardInputs/interfaces/TimestampProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

export const minTimeNumber = 0
export const maxMinuteSecondNumber = 59

type UseAnalyticsCuePoints = [
	Record<AnalyticsCuePoints, TimestampProps>,
	(timestampCommand: AnalyticsCuePoints) => (event: ChangeEvent<HTMLInputElement>) => void,
	(props: Record<AnalyticsCuePoints, TimestampProps>) => void,
]

export const onTimestampSettingChange =
	<T extends string>(analyticsCuePoints: Record<T, TimestampProps>, updateAnalyticsCuePointsState: (props: Record<T, TimestampProps>) => void) =>
	(analyticsCuePoint: T) =>
	(event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value, name },
		} = event

		const numberValue = Number(value)

		const isValid = numberValue <= maxMinuteSecondNumber && numberValue >= minTimeNumber

		if (!isValid) return

		const updatedSetting = {
			...analyticsCuePoints[analyticsCuePoint],
			[name]: numberValue,
		}

		const updatedSettings = { ...analyticsCuePoints, [analyticsCuePoint]: updatedSetting }

		updateAnalyticsCuePointsState(updatedSettings)
	}

export const useAnalyticsCuePoints = (): UseAnalyticsCuePoints => {
	const analyticsCuePoints = useAppSelector(
		({
			dashboardInputs: {
				analyticsCuePointsState: { props: analyticsCuePoints },
			},
		}: StateTree) => analyticsCuePoints,
	)

	const dispatch = useAppDispatch()
	const updateAnalyticsCuePointsState = useCallback(
		(props: Record<AnalyticsCuePoints, TimestampProps>) => analyticsCuePointsState(props)(dispatch),
		[dispatch],
	)

	return [analyticsCuePoints, onTimestampSettingChange(analyticsCuePoints, updateAnalyticsCuePointsState), updateAnalyticsCuePointsState]
}
