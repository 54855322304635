import gameplayGoals from '../assets/json/gameplayGoals.json'
import liveMetaGoals from '../assets/json/liveMetaGoals.json'
import offAirMetaGoals from '../assets/json/offAirMetaGoals.json'
import onboardingGoals from '../assets/json/onboardingGoals.json'
import type { Goal } from '../interfaces/Goal'
import type { TimeFrame } from '../interfaces/TimeFrame'
import {
	AnalyticsCuePoints,
	AnalyticsCuePointsOnDemand,
	Game,
	GameStage,
	ShowType,
	TimestampAutomationCommand,
	OnDemandTimestampCommand,
} from './enums'
import { Country } from './interfaces/Country'

const {
	REACT_APP_SENTRY_DSN: sentryDSN,
	REACT_APP_JWT_USER_NAME: jwtUserName,
	REACT_APP_STANDARD_VIDEO_URL: standardVideoUrl,
	REACT_APP_SHOTS = '',
	REACT_APP_LEAGUES = '',
	REACT_APP_VERSION = '',
} = process.env

const gameNames = Object.values(Game).map((gameName) => ({ key: gameName, name: gameName }))

const countries: Record<Country, { name: string; timeZone: string; localShowHour: number }> = {
	[Country.NO]: {
		name: 'Norway',
		timeZone: 'Europe/Oslo',
		localShowHour: 21,
	},
	[Country.US]: {
		name: 'USA',
		timeZone: 'America/Los_Angeles',
		localShowHour: 18,
	},
}

const goals = [...gameplayGoals, ...offAirMetaGoals, ...liveMetaGoals, onboardingGoals] as unknown as Goal[]

const timeframedGoals: Record<TimeFrame, Goal[]> = { onboarding: [], daily: [], weekly: [], monthly: [], season: [], lifetime: [] }
for (const goal of goals) {
	if (!goal.timeFrames) continue
	for (const timeframe of goal.timeFrames) {
		if (!goal.gameSpecific) {
			timeframedGoals[timeframe].push(goal)
			continue
		}

		for (const { name: gameName } of gameNames) {
			const id = `${goal.id}-${gameName}`
			const requiredStats = { [id]: goal.requiredStats[goal.id] }
			const description = `${goal.description} on ${gameName}`
			timeframedGoals[timeframe].push({ ...goal, id, requiredStats, description })
		}
	}
}

const shots = REACT_APP_SHOTS.split(',')
const stages = [
	{
		key: GameStage.FutureCity,
		name: 'Future City',
	},
	{
		key: GameStage.TropicalIsland,
		name: 'Tropical Island',
	},
	{
		key: GameStage.PinballWorld,
		name: 'Pinball World',
	},
	{
		key: GameStage.DriveInDiner,
		name: 'Drive-in Diner',
	},
	{
		key: GameStage.Candyland,
		name: 'Candy Land',
	},
]
const rallyExclusiveStages = [
	{
		key: GameStage.Room,
		name: 'Room',
	},
]
const leagues = REACT_APP_LEAGUES.split(',')

const timestampAutomationCommandTitles = {
	[TimestampAutomationCommand.StartShow]: 'Start Show',
	[TimestampAutomationCommand.StartGame]: 'Start Game',
	[TimestampAutomationCommand.PrepareCelebration]: 'Celebration',
	[TimestampAutomationCommand.DisplayBeatTheGuest]: 'Beat Guest',
	[TimestampAutomationCommand.PickWinners]: 'Pick Winners',
	[TimestampAutomationCommand.ShowLuckyWinners]: 'Show Lucky Winners',
	[TimestampAutomationCommand.StartPartBGame]: 'Start Part B Game',
	[TimestampAutomationCommand.StopShow]: 'Stop Show',
}

const onDemandTimestampCommandTitles = {
	[OnDemandTimestampCommand.ShowLuckyWinners]: 'Show Lucky Winners',
	[OnDemandTimestampCommand.StartOnDemandGame]: 'Start On Demand Game',
}

const showTypes = [
	{
		key: ShowType.DailyArcadeTournament,
		name: 'Daily Tournament',
	},
	{
		key: ShowType.SeasonFinale,
		name: 'Season Finale',
	},
	{
		key: ShowType.SeasonPremiere,
		name: 'Season Premiere',
	},
	{
		key: ShowType.MegaMonthlyGiveAway,
		name: 'Mega Monthly Give Away',
	},
]

const analyticsCuePointTitles = {
	[AnalyticsCuePoints.OpeningVT]: 'Opening VT',
	[AnalyticsCuePoints.GuestPresentationPartA]: 'Guest Presentation Part A',
	[AnalyticsCuePoints.StartMinigameWithGuest]: 'Start Minigame With Guest',
	[AnalyticsCuePoints.AnnounceNextFeaturedGame]: 'Announce Next Featured Game',
	[AnalyticsCuePoints.ShowLevelForNextFeaturedGame]: 'Show Level For Next Featured Game',
	[AnalyticsCuePoints.GuestIntroductionPartB]: 'Guest Introduction Part B',
	[AnalyticsCuePoints.StartGameplayGuest]: 'Start Gameplay Guest',
}

const analyticsCuePointTitlesOnDemand = {
	[AnalyticsCuePointsOnDemand.ShowLevelForNextFeaturedGame]: 'Show Level For Next Featured Game',
	[AnalyticsCuePointsOnDemand.GuestIntroductionPartB]: 'Guest Introduction Part B',
	[AnalyticsCuePointsOnDemand.StartGameplayGuest]: 'Start Gameplay Guest',
}

export {
	analyticsCuePointTitles,
	analyticsCuePointTitlesOnDemand,
	countries,
	gameNames,
	jwtUserName,
	leagues,
	rallyExclusiveStages,
	sentryDSN,
	shots,
	showTypes,
	stages,
	standardVideoUrl,
	timeframedGoals as timeFramedGoals,
	timestampAutomationCommandTitles,
	onDemandTimestampCommandTitles,
	REACT_APP_VERSION as version,
}
